.home-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.hero-section {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  text-align: center;
  padding: 40px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.hero-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 1.2rem;
}

.section-title {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #0056b3;
}

.about-section,
.applications-section,
.vision-section {
  margin-bottom: 20px;
}

.about-section p,
.applications-section p,
.vision-section p {
  font-size: 1rem;
  color: #555;
}
