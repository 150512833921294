/* Navbar Container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333; /* Navbar background color */
  color: white; /* Text color */
}

/* Logo Styling */
.navbar-logo img.logo {
  width: 50px; /* Adjust the width of the logo */
  height: auto; /* Maintain aspect ratio */
}

/* Navbar Links */
.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px; /* Space between links */
}

.navbar-links li {
  cursor: pointer;
}

/* Title Styles */
.navbar-links .dropdown-title,
.navbar-links a {
  color: white; /* Link color */
  text-decoration: none; /* Remove underline from links */
  font-size: 20px; /* Set consistent font size for titles and links */
}

.navbar-links a:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Dropdown Menu */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  z-index: 1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-content li {
  list-style: none;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  display: block;
  text-decoration: none;
}

.dropdown-content a:hover {
  background-color: #575757;
}

/* Show dropdown content on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
/* Logo Text Styling */
.logo-text {
  color: white; /* Set text color */
  font-size: 24px; /* Adjust font size */
  margin-left: 10px; /* Space between logo and text */
  font-weight: bold; /* Make the text bold */
  align-self: center; /* Center align with logo */
}


@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px;
  }
}
