/* General Layout */
.admin-courses-editor {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .admin-courses-editor h3 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .course-title-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .file-upload-input {
    margin-bottom: 15px;
  }
  
  .admin-courses-editor button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 10px;
  }
  
  .admin-courses-editor button:hover {
    background-color: #0056b3;
  }
  
  .admin-courses-editor button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  /* Course List */
  .course-list {
    margin-top: 20px;
  }
  
  .course-list li {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .course-list li h4 {
    font-size: 18px;
    color: #007bff;
  }
  
  .course-list li button {
    font-size: 14px;
    padding: 8px 15px;
  }
  