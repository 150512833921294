/* Signup.css */

.signup {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .signup h1 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .signup .form-group {
    margin-bottom: 15px;
  }
  
  .signup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .signup input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .signup button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .signup button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .signup .error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .signup .google-signup {
    background-color: #4285f4;
    color: #fff;
    font-size: 16px;
    margin-top: 10px;
    padding: 10px;
  }
  