/* General Layout */
.admin-blog-editor {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.admin-blog-editor h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.admin-blog-editor input[type="text"],
.admin-blog-editor textarea,
.admin-blog-editor select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.admin-blog-editor textarea {
  resize: vertical;
  min-height: 150px;
}

.admin-blog-editor input[type="file"] {
  margin-bottom: 15px;
}

.admin-blog-editor button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-blog-editor button:hover {
  background-color: #0056b3;
}

/* Media Preview Section */
.media-preview {
  margin-top: 15px;
}

.media-preview img,
.media-preview video {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
  margin-top: 10px;
  border: 1px solid #ddd;
  padding: 5px;
}

.media-preview video {
  background-color: #000;
}

/* Blog List */
.blog-list {
  margin-top: 40px;
}

.blog-list h4 {
  font-size: 20px;
  color: #333;
}

.blog-list p {
  color: #666;
  line-height: 1.6;
}

.blog-list img,
.blog-list video {
  max-width: 100px;
  max-height: 100px;
  margin: 10px 0;
  border-radius: 4px;
}

.blog-list button {
  margin-right: 10px;
}

.blog-list li {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Style for blog item */
.blog-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

.serial-no {
  font-weight: bold;
  margin-right: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-blog-editor {
    padding: 10px;
  }

  .admin-blog-editor input[type="text"],
  .admin-blog-editor textarea,
  .admin-blog-editor select {
    font-size: 14px;
  }

  .admin-blog-editor button {
    font-size: 14px;
    padding: 8px 15px;
  }

  .blog-list h4 {
    font-size: 18px;
  }

  .media-preview img,
  .media-preview video {
    max-height: 200px;
  }
}

/* Improved Styles for Media */
.blog-images img,
.blog-videos video {
  margin-right: 10px;
  max-width: 100px;
  max-height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.image-preview img {
  margin-right: 10px;
  max-width: 300px;
  border: 1px solid #ccc;
  padding: 5px;
}
