/* Container styling for the entire quiz section */
.quizzes-by-subject {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Quiz title */
.quizzes-by-subject h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Each quiz item container */
.quiz-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

.quiz-item:last-child {
  border-bottom: none;
}

/* Quiz question */
.quiz-item h2 {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

/* Quiz marks and time limit */
.quiz-item p {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

/* Quiz options list */
.quiz-options {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Individual quiz option */
.quiz-option {
  cursor: pointer;
  margin: 8px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect for quiz options */
.quiz-option:hover {
  background-color: #f0f0f0;
}

/* Correct answer styling */
.quiz-option.correct {
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
}

/* Incorrect answer styling */
.quiz-option.incorrect {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

/* Quiz answer details */
.quiz-answer {
  margin-top: 10px;
  padding: 10px;
  background-color: #e9ecef;
  border-left: 4px solid #007bff;
  font-style: italic;
}

.quiz-answer p {
  margin: 0 0 8px 0;
  color: #333;
}

/* Responsive design */
@media (max-width: 600px) {
  .quizzes-by-subject {
      padding: 15px;
  }

  .quiz-item h2 {
      font-size: 16px;
  }

  .quiz-option {
      padding: 8px;
  }
}
