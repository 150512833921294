.dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.dashboard-header {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  max-width: 500px;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.search-icon {
  margin-right: 10px;
  color: #aaa;
}

.search-input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: transparent;
}

.section {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 10px;
}

.section-content {
  margin-top: 10px;
}

.no-matching-sections {
  text-align: center;
  font-size: 1.2rem;
  color: #999;
}
