/* MCQsBySubject.css */

/* General container styling */
.mcqs-by-subject {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Heading styles */
.mcqs-by-subject h1 {
  font-size: 28px;
  color: #007bff;
  margin-bottom: 20px;
  text-align: center;
}

/* Loading and error messages */
.loading,
.error {
  font-size: 18px;
  color: #ff6f61;
  text-align: center;
  margin-top: 20px;
}

/* MCQ item styles */
.mcq-item {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
}

.mcq-question {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

/* Options list styles */
.options-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 15px;
}

.option-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option-item:hover {
  background-color: #e9ecef;
}

.option-item input {
  margin-right: 10px;
}

/* MCQ details (answer and explanation) */
.mcq-details {
  margin-top: 15px;
  padding: 10px;
  background-color: #f1f3f5;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.mcq-details p {
  font-size: 16px;
  color: #555;
  margin-bottom: 8px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .mcqs-by-subject {
    padding: 15px;
  }

  .mcq-question {
    font-size: 18px;
  }

  .option-item {
    font-size: 14px;
  }

  .mcq-details p {
    font-size: 14px;
  }
}
