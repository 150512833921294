.research-container {
    padding: 20px;
  }
  
  .research-paper {
    margin-bottom: 20px;
  }
  
  .research-title {
    cursor: pointer;
    color: #007bff;
  }
  
  button {
    margin-right: 10px;
    margin-top: 10px;
  }
  