/* Container Styling */
.research-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.research-header {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Research Paper List */
.research-list {
  margin-top: 20px;
}

.research-paper {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.research-paper:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.research-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
  margin: 0;
  transition: color 0.3s;
}

.research-title:hover {
  color: #0056b3;
}

.research-title.active {
  color: #0056b3;
}

.research-content {
  margin-top: 15px;
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

/* Error and Loading */
.loading,
.error,
.no-research-papers {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}

.error {
  color: red;
}

.no-research-papers {
  color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .research-container {
    padding: 15px;
  }

  .research-header {
    font-size: 1.8rem;
  }

  .research-title {
    font-size: 1.3rem;
  }

  .research-content {
    font-size: 0.95rem;
  }
}
