/* Login.css */

.login {
    padding: 20px;
    background-color: #f4f4f4;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .login form {
    display: flex;
    flex-direction: column;
  }
  
  .login input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .login button {
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .login button:disabled {
    background-color: #0056b3;
    cursor: not-allowed;
  }
  
  .login .error {
    color: #dc3545;
    margin-bottom: 15px;
    text-align: center;
  }
  /* Login.css */

.google-signin {
    margin-top: 20px;
    text-align: center;
  }
  
  .google-button {
    padding: 10px;
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .google-button:hover {
    background-color: #357ae8;
  }
  