/* Right Sidebar Styling */
.right-sidebar {
  width: 200px; /* Fixed width for the sidebar */
  position: fixed; /* Fixed position to stay in place */
  top: 100px; /* Adjust based on your header */
  right: 0; /* Align to the right of the screen */
  background-color: #f4f4f4; /* Light background color */
  padding: 20px; /* Padding for content */
  border-left: 1px solid #ddd; /* Light border for separation */
  height: calc(100% - 100px); /* Full height minus header */
  overflow-y: auto; /* Enable scrolling if content exceeds height */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 1000; /* Ensure it stays above other elements */
}

/* AdSense Placeholder Styling */
.adsense-placeholder {
  width: 160px; /* Fixed width for ads */
  height: 600px; /* Fixed height for ads */
  background-color: #ccc; /* Light gray background for visibility */
  text-align: center; /* Center-align text within the placeholder */
  padding-top: 10px; /* Padding at the top */
  font-size: 14px; /* Adjust text size */
  color: #666; /* Slightly darker text for contrast */
  margin-bottom: 20px; /* Space below the ads */
  border: 1px dashed #aaa; /* Dashed border for ads placeholder */
}

/* Sidebar Links Styling */
.sidebar-links {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.sidebar-links li {
  margin-bottom: 10px; /* Space between each link */
}

.sidebar-links li a {
  text-decoration: none; /* Remove underline from links */
  color: #333; /* Dark color for the link text */
  font-weight: 500; /* Slightly bold text for readability */
  transition: color 0.3s, transform 0.2s; /* Smooth transition for hover effect */
}

.sidebar-links li a:hover {
  color: #007bff; /* Change color on hover for visibility */
  transform: translateX(5px); /* Slight slide effect on hover */
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .right-sidebar {
      width: 100%; /* Full width for mobile devices */
      position: relative; /* Change to relative position */
      height: auto; /* Auto height for mobile devices */
      top: 0; /* Reset top position */
      border-left: none; /* Remove left border */
      padding: 10px; /* Adjust padding */
      box-shadow: none; /* Remove shadow for simplicity */
  }

  .adsense-placeholder {
      width: 100%; /* Full width for ads on mobile */
      height: auto; /* Auto height for ads on mobile */
      margin-bottom: 10px; /* Adjust spacing */
  }

  .sidebar-links li a {
      font-size: 16px; /* Slightly larger font size for mobile */
  }
}
