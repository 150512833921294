.download-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif; /* Ensure consistent font styling */
}

.download-container h1 {
  text-align: center;
  font-size: 2.5rem; /* Slightly larger font for prominence */
  margin-bottom: 2rem;
  color: #333; /* Darker color for better readability */
}

.download-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.download-container li {
  background-color: #ffffff; /* Pure white background for contrast */
  margin: 1rem 0;
  padding: 1.5rem; /* Increased padding for better spacing */
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow for modern look */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effect */
}

.download-container li:hover {
  transform: translateY(-4px); /* Subtle lift on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.download-container li span {
  font-weight: bold;
  color: #555; /* Softer color for serial number */
}

.download-container li p {
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5; /* Improved readability with line-height */
  color: #666; /* Slightly lighter text color */
}

.download-container li a {
  display: inline-block; /* Inline but respects padding and spacing */
  font-size: 1.1rem;
  font-weight: bold;
  color: #007bff; /* Consistent blue for links */
  text-decoration: none;
  margin: 0.5rem 0;
  padding: 0.3rem 0.5rem; /* Add slight padding for clickable area */
  border: 1px solid transparent; /* Transition for focus state */
  transition: color 0.2s, border-color 0.2s; /* Smooth link hover effect */
}

.download-container li a:hover,
.download-container li a:focus {
  text-decoration: underline;
  color: #0056b3; /* Darker blue on hover */
  border-color: #007bff; /* Visible border on focus */
}

.download-container li p strong {
  font-weight: bold;
  color: #444; /* Consistent dark tone for strong elements */
}

.download-container .error {
  color: red;
  text-align: center;
  font-size: 1.1rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .download-container {
    padding: 1rem;
  }

  .download-container h1 {
    font-size: 2rem;
  }

  .download-container li {
    padding: 1rem;
  }

  .download-container li a {
    font-size: 1rem;
  }
}
