/* BlogDetail.css */

/* Container styling */
.blog-detail-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* Heading styling */
.blog-detail-container h1 {
  text-align: center;
  font-size: 2.5em;
  color: #333;
  margin-bottom: 40px;
}

/* Blog post styling */
.blog-post {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* Blog title styling */
.blog-post h2 {
  font-size: 1.8em;
  color: #007bff;
  margin-bottom: 15px;
}

/* Blog content styling */
.blog-post p {
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
}

/* Image styling */
.blog-post img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* No blogs message */
.no-blogs {
  text-align: center;
  font-size: 1.2em;
  color: #999;
}
