/* General blog container styling */
.blog-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333; /* Set default text color */
  background-color: #fff; /* Background for better contrast */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 8px;
}

/* Heading for the main title */
.blog-container h1 {
  text-align: center;
  font-size: 2.5em;
  color: #333;
  margin-bottom: 40px;
  font-weight: bold;
}

/* Subject section styling */
.subject-section {
  margin-bottom: 40px;
}

.subject-section h2 {
  font-size: 2em;
  color: #007bff;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
  transition: color 0.3s;
}

.subject-section h2:hover {
  color: #0056b3; /* Darker blue on hover */
}

/* Blog post styling */
.blog-post {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: transform 0.2s, box-shadow 0.2s;
}

.blog-post:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.blog-post h3 {
  font-size: 1.8em;
  color: #222;
  margin-bottom: 15px;
  font-weight: bold;
}

.blog-post h3:hover {
  color: #007bff; /* Interactive hover effect */
}

/* Blog image styling */
.blog-post img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.blog-post img:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Blog content styling */
.blog-post p {
  font-size: 1.1em;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

.blog-post a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.blog-post a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* No blogs available message */
.no-blogs {
  font-size: 1.2em;
  color: #999;
  text-align: center;
  margin-top: 20px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .blog-container {
    width: 100%;
    padding: 10px;
  }

  .blog-post h3 {
    font-size: 1.5em;
  }

  .subject-section h2 {
    font-size: 1.6em;
  }

  .blog-post {
    padding: 15px;
  }
}

/* Accessibility: Focus outline for better navigation */
.subject-section h2:focus,
.blog-post h3:focus,
.blog-post a:focus {
  outline: 2px solid #0056b3;
  outline-offset: 2px;
}
