/* Ensures the page takes up the full height of the viewport */
html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif; /* Optional: Set a default font */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Makes the content area flexible to push the footer down */
.content-wrapper {
  flex: 1;
  padding: 20px;
}

/* Layout for the sidebars and main content */
.main-layout {
  display: flex;
  justify-content: space-between; /* Ensures the main content stays centered */
  flex-grow: 1;
  padding: 20px;
  position: relative;
}

/* Main content styles */
.main-content {
  margin-right: 220px; /* Adjust to account for the right sidebar */
  padding: 20px;
  flex-grow: 1; /* Allow main content to grow and fill space */
}

/* Right Sidebar */
.right-sidebar {
  width: 200px;
}

/* Footer Styles */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

/* Responsive Styles */
@media (max-width: 500px) {
  .main-layout {
    flex-direction: column; /* Stack layout vertically on small screens */
  }

  .main-content {
    margin-right: 0; /* Remove right margin for main content */
    padding: 10px; /* Adjust padding */
  }

  .right-sidebar {
    width: 100%; /* Full width for right sidebar on small screens */
    margin-top: 10px; /* Add space above sidebar */
    position: relative; /* Change position to relative */
  }
}
