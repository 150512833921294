.courses {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.courses h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.courses-list {
  list-style: none;
  padding: 0;
}

.course-item {
  margin: 10px 0;
}

.course-title {
  display: block;
  background: #351da0;
  padding: 10px;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.course-title:hover,
.course-title:focus {
  background: #0e19b3;
}

.course-description {
  padding: 10px;
  background: #f9f9f9;
  border-left: 4px solid #ccc;
  margin-top: 5px;
}

.loading {
  color: #555;
}

.error {
  color: red;
}
