/* Container styling for the quiz editor */
.admin-quiz-editor {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    max-width: 900px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Page title */
.admin-quiz-editor h1 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* Form sections */
.admin-quiz-editor .form-section {
    margin-bottom: 20px;
}

/* Section labels */
.admin-quiz-editor label {
    font-size: 14px;
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
    display: block;
}

/* Input fields */
.admin-quiz-editor input[type="text"],
.admin-quiz-editor input[type="number"],
.admin-quiz-editor select,
.admin-quiz-editor textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;
    box-sizing: border-box;
    font-size: 14px;
}

/* Rich text editor */
.admin-quiz-editor .quill {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
}

/* Buttons */
.admin-quiz-editor button {
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}

.admin-quiz-editor button:hover {
    background-color: #0056b3;
}

.admin-quiz-editor button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Error messages */
.admin-quiz-editor .error-message {
    color: #d9534f;
    font-size: 14px;
    margin-top: 5px;
}

/* Success messages */
.admin-quiz-editor .success-message {
    color: #28a745;
    font-size: 14px;
    margin-top: 5px;
}

/* Bulk upload section */
.bulk-upload {
    margin-top: 20px;
}

.bulk-upload input[type="file"] {
    display: block;
    margin-top: 10px;
}

/* Quiz list */
.quiz-list {
    margin-top: 30px;
    padding: 0;
    list-style-type: none;
}

.quiz-list li {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
}

.quiz-list li:last-child {
    border-bottom: none;
}

/* Edit and delete buttons */
.quiz-list button {
    padding: 5px 10px;
    font-size: 12px;
    margin-right: 5px;
}

.quiz-list .edit-button {
    background-color: #ffc107;
    color: #fff;
}

.quiz-list .edit-button:hover {
    background-color: #e0a800;
}

.quiz-list .delete-button {
    background-color: #dc3545;
    color: #fff;
}

.quiz-list .delete-button:hover {
    background-color: #c82333;
}

/* Responsive design */
@media (max-width: 768px) {
    .admin-quiz-editor {
        padding: 15px;
    }

    .admin-quiz-editor button {
        width: 100%;
        margin-top: 10px;
    }
}
